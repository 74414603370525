import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { getDuration, translate } from 'utilities';
import { Theme } from '../../theme';

const minTimeValue = 0;
const secondsScale = 1;
const minutesScale = 60;
const hoursScale = 60 * 60;
const daysScale = 24 * 60 * 60;

const useStyles = makeStyles((theme: Theme) => ({
    inputContainer: {
        boxSizing: 'border-box',
        flexGrow: 1,
        height: theme.custom?.mainSizes.input.height,
        border: `${theme.custom?.mainSizes.common.defaultBorderSize}px solid ${theme.custom?.palette.divider}`,
        borderRadius: `${theme.custom?.mainSizes.common.tinyBorderRadius}px`,
        padding: '1px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.custom?.palette.background.main,
        pointer: 'default',
        '&:hover': {
            border: `${theme.custom?.mainSizes.common.defaultBorderSize}px solid ${theme.custom?.palette.primary.main}`,
        },
    },
    timeInputContainer: {
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '16px',
    },
    timeInput: {
        ...theme.custom?.typography.text,
        type: 'hidden',
        border: 'none',
        outline: 'none',
        textAlign: 'center',
        color: theme.custom?.palette.text.primary,
        backgroundColor: theme.custom?.palette.background.main,
        width: '40px',
        '&:focus': {
            color: theme.custom?.palette.primary.main,
        },
        '&:hover': {
            color: theme.custom?.palette.primary.main,
        },
    },
    externalContainer: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'row',
    },
    unitContainer: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: `${theme.custom?.typography.tinyText.fontSize}px`,
        color: theme.custom?.palette.text.secondary,
        lineHeight: '6px',
    },
    divider: {
        ...theme.custom?.typography.text,
        color: theme.custom?.palette.text.primary,
    },
}));

interface TimeSpanProps {
    showDays?: boolean;
    showHours?: boolean;
    showMinutes?: boolean;
    showSeconds?: boolean;
    value: number;
    width?: number;
    position?: string;
    minValue?: number;
    maxValue?: number;
    onChange: (value: number) => void;
}

/**
 * Логика работы компонента следующая:
 * - если hover - работает как обычные часы, то есть при прокрутке или управлении стрелками - 00 переходит в 59 и наоборот
 * - если focus - то работает как счетчик, пока фокус не сброшен, то есть при прокрутке или управлении стрелками - по достижению 59 продолжает увеличиваться. При потере фокуса пересчитывается в привычный вид часов
 *
 **/
export const TimeSpan: React.FC<TimeSpanProps> = (props) => {
    let {
        showDays = false,
        showHours = false,
        showMinutes = false,
        showSeconds = false,
        width,
        value = 0,
        position = 'center',
        minValue,
        maxValue,
        onChange,
    } = props;
    let classes = useStyles();

    const timeUnits = {
        showDays: showDays,
        showHours: showHours,
        showMinutes: showMinutes,
        showSeconds: showSeconds,
    };

    const [days, setDays] = React.useState<number>(0);
    const [hours, setHours] = React.useState<number>(0);
    const [minutes, setMinutes] = React.useState<number>(0);
    const [seconds, setSeconds] = React.useState<number>(0);

    let inputContainerRef = React.useRef<HTMLInputElement>(null);
    let inputDayRef = React.useRef<HTMLInputElement>(null);
    let inputHoursRef = React.useRef<HTMLInputElement>(null);
    let inputMinuteRef = React.useRef<HTMLInputElement>(null);
    let inputSecondRef = React.useRef<HTMLInputElement>(null);
    let [focusedDays, setFocusedDays] = React.useState<boolean>(false);
    let [focusedHours, setFocusedHours] = React.useState<boolean>(false);
    let [focusedMinutes, setFocusedMinutes] = React.useState<boolean>(false);
    let [focusedSeconds, setFocusedSeconds] = React.useState<boolean>(false);

    useEffect(() => {
        const cancelWheel = (event: WheelEvent) => event.preventDefault();
        inputContainerRef.current?.addEventListener('wheel', cancelWheel);
        return () => inputContainerRef.current?.removeEventListener('wheel', cancelWheel);
    }, []);

    useEffect(() => {
        const duration = getDuration(value, timeUnits);
        setDays(duration.days);
        setHours(duration.hours);
        setMinutes(duration.minutes);
        setSeconds(duration.seconds);
    }, []);

    const calcDuration = (value: number) => {
        let result = value;

        if (typeof minValue === 'number' && value < minValue) {
            result = minValue;
        }

        if (typeof maxValue === 'number' && value > maxValue) {
            result = maxValue;
        }

        onChange(result);

        const duration = getDuration(result, timeUnits);
        setDays(duration.days);
        setHours(duration.hours);
        setMinutes(duration.minutes);
        setSeconds(duration.seconds);
    };

    const onSecondsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let numberValue = getValue(event.target.value);
        setSeconds(numberValue);
    };

    const onBlurSeconds = () => {
        setFocusedSeconds(false);
        calcDuration(getUpdateValue(days, hours, minutes, seconds));
    };

    const onSecondsWheel = (event: React.WheelEvent<HTMLInputElement>) => {
        if (!focusedSeconds) {
            if (value < 1 && event.deltaY > 0) {
                return;
            }
            let numberValue = modifyFullValueByAction(value, event.deltaY, secondsScale);
            numberValue = correctNegativeValue(numberValue, minTimeValue);
            calcDuration(numberValue);
        } else {
            if (seconds < 1 && event.deltaY > 0) {
                return;
            }
            let numberValue = modifyValueByAction(seconds, event.deltaY);
            numberValue = correctNegativeValue(numberValue, minTimeValue);
            setSeconds(numberValue);
        }
    };

    const onSecondsKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        let numberValue = getDeltaFromKeyEvent(event, inputSecondRef, seconds);
        if (numberValue !== undefined) {
            setSeconds(numberValue);
        }
    };

    //actions with minutes
    const onMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let numberValue = getValue(event.target.value);
        setMinutes(numberValue);
    };

    const onBlurMinutes = () => {
        setFocusedMinutes(false);
        calcDuration(getUpdateValue(days, hours, minutes, seconds));
    };

    const onMinutesWheel = (event: React.WheelEvent<HTMLInputElement>) => {
        if (!focusedMinutes) {
            const minutesFromValue = Math.trunc(value / minutesScale);
            if (minutesFromValue < 1 && event.deltaY > 0) {
                return;
            }
            let numberValue = modifyFullValueByAction(value, event.deltaY, minutesScale);
            numberValue = correctNegativeValue(numberValue, minTimeValue);
            calcDuration(numberValue);
        } else {
            if (minutes < 1 && event.deltaY > 0) {
                return;
            }
            let numberValue = modifyValueByAction(minutes, event.deltaY);
            numberValue = correctNegativeValue(numberValue, minTimeValue);
            setMinutes(numberValue);
        }
    };

    const onMinutesKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        let numberValue = getDeltaFromKeyEvent(event, inputMinuteRef, minutes);
        if (numberValue !== undefined) {
            setMinutes(numberValue);
        }
    };

    //actions with hours
    const onHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let numberValue = getValue(event.target.value);
        setHours(numberValue);
    };

    const onBlurHours = () => {
        setFocusedHours(false);
        calcDuration(getUpdateValue(days, hours, minutes, seconds));
    };

    const onHoursWheel = (event: React.WheelEvent<HTMLInputElement>) => {
        if (!focusedHours) {
            const hoursFromValue = Math.trunc(value / hoursScale);
            if (hoursFromValue < 1 && event.deltaY > 0) {
                return false;
            }
            let numberValue = modifyFullValueByAction(value, event.deltaY, hoursScale);
            numberValue = correctNegativeValue(numberValue, minTimeValue);
            calcDuration(numberValue);
        } else {
            if (hours < 1 && event.deltaY > 0) {
                return false;
            }
            let numberValue = modifyValueByAction(hours, event.deltaY);
            numberValue = correctNegativeValue(numberValue, minTimeValue);
            setHours(numberValue);
        }
    };

    const onHoursKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        let numberValue = getDeltaFromKeyEvent(event, inputHoursRef, hours);
        if (numberValue !== undefined) {
            setHours(numberValue);
        }
    };

    //actions with days
    const onDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let numberValue = getValue(event.target.value);
        setDays(numberValue);
    };

    const onBlurDays = () => {
        setFocusedDays(false);
        calcDuration(getUpdateValue(days, hours, minutes, seconds));
    };

    const onDaysWheel = (event: React.WheelEvent<HTMLInputElement>) => {
        if (days < 1 && event.deltaY > 0) {
            return;
        }
        let numberValue = modifyValueByAction(days, event.deltaY);
        numberValue = correctNegativeValue(numberValue, minTimeValue);
        if (!focusedDays) {
            calcDuration(getUpdateValue(numberValue, hours, minutes, seconds));
        } else {
            setDays(numberValue);
        }
    };

    const onDaysKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        let numberValue = getDeltaFromKeyEvent(event, inputDayRef, days);
        if (numberValue !== undefined) {
            setDays(numberValue);
        }
    };

    let containerStyle: React.CSSProperties = {};
    containerStyle.width = width;

    if (position) {
        containerStyle.justifyContent = position;
    }

    return (
        <div className={classes.inputContainer} style={containerStyle} ref={inputContainerRef}>
            {showDays && (
                <div className={classes.externalContainer}>
                    <div className={classes.timeInputContainer}>
                        <div>
                            <input
                                ref={inputDayRef}
                                className={classes.timeInput}
                                value={days}
                                onFocus={() => setFocusedDays(true)}
                                onBlur={onBlurDays}
                                onWheel={onDaysWheel}
                                onKeyUp={onDaysKeyDown}
                                onChange={onDaysChange}
                            />
                        </div>
                        <div className={classes.unitContainer}>{translate('Day1')}</div>
                    </div>
                    {showHours && <div className={classes.divider}>:</div>}
                </div>
            )}
            {showHours && (
                <div className={classes.externalContainer}>
                    <div className={classes.timeInputContainer}>
                        <div>
                            <input
                                ref={inputHoursRef}
                                className={classes.timeInput}
                                value={hours < 10 ? '0' + hours.toString() : hours}
                                onFocus={() => setFocusedHours(true)}
                                onBlur={onBlurHours}
                                onWheel={onHoursWheel}
                                onKeyUp={onHoursKeyDown}
                                onChange={onHoursChange}
                            />
                        </div>
                        <div className={classes.unitContainer}>{translate('Hour')}</div>
                    </div>
                    {showMinutes && <div className={classes.divider}>:</div>}
                </div>
            )}
            {showMinutes && (
                <div className={classes.externalContainer}>
                    <div className={classes.timeInputContainer}>
                        <div>
                            <input
                                ref={inputMinuteRef}
                                className={classes.timeInput}
                                value={minutes < 10 ? '0' + minutes.toString() : minutes}
                                onFocus={() => setFocusedMinutes(true)}
                                onBlur={onBlurMinutes}
                                onWheel={onMinutesWheel}
                                onKeyUp={onMinutesKeyDown}
                                onChange={onMinutesChange}
                            />
                        </div>
                        <div className={classes.unitContainer}>{translate('MinutesShort')}</div>
                    </div>
                    {showSeconds && <div className={classes.divider}>:</div>}
                </div>
            )}
            {showSeconds && (
                <div className={classes.externalContainer}>
                    <div className={classes.timeInputContainer}>
                        <div>
                            <input
                                ref={inputSecondRef}
                                className={classes.timeInput}
                                value={seconds < 10 ? '0' + seconds.toString() : seconds}
                                onFocus={() => setFocusedSeconds(true)}
                                onBlur={onBlurSeconds}
                                onWheel={onSecondsWheel}
                                onKeyUp={onSecondsKeyDown}
                                onChange={onSecondsChange}
                            />
                        </div>
                        <div className={classes.unitContainer}>{translate('SecondsShort')}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

function getValue(textValue: string): number {
    let result = parseInt(textValue);
    if (isNaN(result)) {
        result = 0;
    }
    return correctNegativeValue(result, 0);
}

function correctNegativeValue(value: number, min: number): number {
    if (value < min) {
        return 0;
    }
    return value;
}

function modifyValueByAction(value: number, actionValue: number): number {
    if (actionValue > 0) {
        value = value - 1;
    }
    if (actionValue < 0) {
        value = value + 1;
    }
    return value;
}

function modifyFullValueByAction(value: number, actionValue: number, scale: number): number {
    if (actionValue > 0) {
        value = value - 1 * scale;
    }
    if (actionValue < 0) {
        value = value + 1 * scale;
    }
    return value;
}

function getActionValueFromKeyEvent(event: React.KeyboardEvent) {
    let result = 0;
    if (event.key == 'ArrowUp') {
        result = -1;
    }
    if (event.key == 'ArrowDown') {
        result = 1;
    }
    return result;
}

function getUpdateValue(days: number, hours: number, minutes: number, seconds: number) {
    let result = days * daysScale + hours * hoursScale + minutes * minutesScale + seconds;
    return result;
}

function getDeltaFromKeyEvent(
    event: React.KeyboardEvent<HTMLInputElement>,
    ref: React.RefObject<HTMLInputElement>,
    value: number,
) {
    if (event.key == 'Enter') {
        ref.current?.blur();
        return;
    }
    if (value < 1 && event.key == 'ArrowDown') {
        return;
    }
    let actionValue = getActionValueFromKeyEvent(event);
    if (actionValue == 0) {
        return;
    } else {
        event.preventDefault();
    }
    let numberValue = modifyValueByAction(value, actionValue);
    numberValue = correctNegativeValue(numberValue, minTimeValue);
    return numberValue;
}
